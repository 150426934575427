import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Row, Col } from 'antd';
import { Rule } from 'rc-field-form/lib/interface';
import PhoneInput, { PhoneNumber } from 'antd-phone-input';

import styles from './gift_card_store_billing_info.module.scss';
import { IGiftCardStoreBillingInfoProps } from './gift_card_store_billing_info.types';

const GiftCardStoreBillingInfo: React.FC<IGiftCardStoreBillingInfoProps> = ({ form }) => {
  const { t } = useTranslation();

  const validatePhone = (_: Rule, { valid }: PhoneNumber): Promise<void> => {
    if (valid === undefined) {
      return Promise.resolve();
    }
    if (valid()) {
      return Promise.resolve();
    }

    return Promise.reject(new Error(t('validation_messages.required')));
  };

  const handlePhoneChange = (value: PhoneNumber) => {
    form.setFieldValue(['billing', 'phone'], value);
  };

  const requiredRules = [{ required: true, message: t('validation_messages.required') }];
  const requiredPhoneRules = [{ required: true, message: t('validation_messages.required'), validator: validatePhone }];

  return (
    <div data-testid="GiftCardStoreBillingInfo">
      <Row gutter={16} className={styles.itemRow}>
        <Col xs={24} sm={12}>
          <Form.Item name={['billing', 'name']} rules={requiredRules} label={t('general.payer_name')}>
            <Input />
          </Form.Item>
          <Form.Item
            name={['billing', 'phone']}
            rules={requiredPhoneRules}
            label={t('general.payer_number')}
            data-testid="PayerPhoneInput"
          >
            <PhoneInput
              enableSearch
              disableParentheses
              value={form.getFieldValue(['billing', 'phone'])}
              onChange={handlePhoneChange}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item name={['billing', 'address']} rules={requiredRules} label={t('general.payer_address')}>
            <Input />
          </Form.Item>
          <Form.Item
            name={['billing', 'email']}
            rules={[...requiredRules, { type: 'email', message: t('general.please_enter_valid_email') }]}
            label={t('general.payer_email')}
            getValueFromEvent={e => e.target.value.trim()}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default GiftCardStoreBillingInfo;
