// Generated with util/create-component.js
import React, { useState, useContext, useEffect, useMemo } from 'react';
import { Route, Link, Switch, Redirect, useLocation } from 'react-router-dom'; // Import necessary components
import { Menu, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import PropertyDetails from 'components/property_details/property_details';
import PropertyUnits from 'components/property_units/property_units';
import GiftCardsStoreSettingsPage from 'pages/gift_cards_store_settings_page/gift_cards_store_settings_page';

import USER_ROLE from 'constants/user_role';
import {
  AuthenticationDataContext,
  PropertySettingsDataContext,
  PropertySettingsActionsContext,
} from 'containers/data_context';
import routes from '../../routing/routes';

import styles from './property_settings_page.module.scss';
import PaymentProvidersPage from 'components/payment_providers_page/payment_providers_page';
import SupplierManagerPage from 'pages/supplier_manager_page/supplier_manager_page';
import { IProperty } from 'components/admin/properties/properties_list/properties_list.types';

const PropertySettingsPage: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState('propertyDetails');

  const { t } = useTranslation();
  const location = useLocation();
  const propertySettingsData = useContext(PropertySettingsDataContext);
  const { userRole, user } = useContext(AuthenticationDataContext);

  const { loadSuppliersList } = useContext(PropertySettingsActionsContext);

  const {
    propertiesList: { data: properties },
    selectedProperty,
  } = propertySettingsData;

  const menuItems = useMemo(
    () => [
      {
        key: 'propertyDetails',
        label: <Link to={routes.settingsPagePropertyDetails}>{t('general.property_details_title')}</Link>,
      },
      {
        key: 'roomAndRates',
        label: <Link to={routes.settingsPageRoomsAndRates}>{t('general.rooms_and_rates')}</Link>,
      },
      {
        key: 'giftCards',
        label: <Link to={routes.settingsPageStores}>{t('gift_card.settings_stores')}</Link>,
      },
      {
        key: 'paymentProviders',
        label: <Link to={routes.settingsPagePaymentProviders}>{t('general.payment_providers')}</Link>,
      },
    ],
    [user, selectedProperty],
  );

  const isManager = useMemo(() => {
    return user?.reservaHotels?.find((rh: IProperty) => rh.propertyId === selectedProperty && rh.role === 'manager');
  }, [user, selectedProperty]);

  useEffect(() => {
    const currentPath = location.pathname;
    const matchingMenuItem = menuItems.find(menuItem => currentPath.startsWith(menuItem?.label?.props?.to));

    if (matchingMenuItem) {
      setSelectedTab(matchingMenuItem.key);
    }
  }, [location.pathname, menuItems]);

  if (isManager) {
    menuItems.push({
      key: 'admin',
      label: <Link to={routes.settingsPageAdmin}>{t('link.admin')}</Link>,
    });
  }

  useEffect(() => {
    if (!properties?.length && userRole === USER_ROLE.SUPPLIER) {
      loadSuppliersList();
    }
  }, []);

  return (
    <div data-testid="PropertySettingsPage" className={styles.root}>
      <Row className={styles.property_settings_container} gutter={[0, 0]}>
        <Col xs={24} lg={4}>
          <Menu selectedKeys={[selectedTab]} mode="inline" items={menuItems} />
        </Col>
        <Col xs={24} lg={20} className={styles.details_container}>
          <Switch>
            <Route path={routes.settingsPagePropertyDetails}>
              <PropertyDetails />
            </Route>
            <Route path={routes.settingsPageRoomsAndRates}>
              <PropertyUnits />
            </Route>
            <Route path={routes.settingsPageStores}>
              <GiftCardsStoreSettingsPage />
            </Route>
            <Route path={routes.settingsPagePaymentProviders}>
              <PaymentProvidersPage />
            </Route>
            {isManager && (
              <Route path={routes.settingsPageAdmin}>
                <SupplierManagerPage />
              </Route>
            )}
            <Route path="*">
              <Redirect to={routes.default} />
            </Route>
          </Switch>
        </Col>
      </Row>
    </div>
  );
};

export default PropertySettingsPage;
